import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { CheckboxInput, useScreenMode } from "../../Components";
import { getNameSlug, myFilter } from "../../Managers";
import { showAppModal } from "../../AppState";
import { ExportModal } from "./Modals/ExportModal";
import { getIntervalOptions, getTransmitIntervalOptions } from "../../Managers/DeviceService";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExportFilter, IReportSensor } from "./Export";
import { Box } from "@mui/material";
import { Collapse } from "react-collapse";
import ClickAwayListener from "react-click-away-listener";
import { IExportPreset } from "../../Managers/Types";
import { WindowSize } from "../../Enums";

interface IExportItemsProps {
  filters: ExportFilter;
  dateRange: Date[];
  sensors: IReportSensor[];
  preset?: IExportPreset;
  selectedSensors: IReportSensor[];
  setSelectedSensors: (sensors: IReportSensor[]) => void;
  savePreset: (preset: IExportPreset) => Promise<void>;
}

export const ExportItems: React.FC<IExportItemsProps> = ({
  savePreset,
  filters,
  dateRange,
  setSelectedSensors,
  selectedSensors,
  sensors,
  preset,
}) => {
  const [expandedSensorId, setExpandedSensorId] = useState<number>();
  const [exportMenuOpen, setExportMenuOpen] = useState(false);

  const { t } = useTranslation(["common", "export", "sensor_types"]);
  const mode = useScreenMode();

  useEffect(() => {
    if (preset?.sensorConfig) {
      setSelectedSensors(sensors.filter((s) => preset.sensorConfig?.some((config) => config._id === s._id)));
    }
  }, [preset]);

  const selectSensor = (sensor: IReportSensor) => {
    const sensorArr = [...selectedSensors];
    const selectedSensorIndex = selectedSensors.findIndex((s) => s._id === sensor._id);

    if (selectedSensorIndex > -1) {
      sensorArr.splice(selectedSensorIndex, 1);
    } else {
      sensorArr.push(sensor);
    }

    setSelectedSensors(sensorArr);
  };

  const getFilters = useCallback(() => {
    return [
      ...filters.reduce((arr, filter) => {
        const index = arr.findIndex((item) => item.prop === filter.key);
        if (index > -1 && Array.isArray(arr[index].value)) {
          arr[index].value.push(filter.value);
        } else if (index > -1) {
          arr[index].value = [arr[index].value, filter.value];
        } else {
          arr.push({ prop: filter.key, value: filter.value });
        }
        return arr;
      }, [] as { prop: keyof IReportSensor; value: any }[]),

      // { prop: "last_report_time" as keyof IReportSensor, value: dateRange },
    ];
  }, [filters, dateRange]);

  return (
    <>
      {mode === WindowSize.MOBILE ? (
        <>
          <section className="main-panel export-main">
            <Box className="mobile-list">
              {myFilter(sensors, getFilters()).map((sensor) => (
                <div className="mobile-list-item mobile-export-item">
                  <CheckboxInput
                    className="checkbox-teal"
                    checked={selectedSensors.some((s) => s._id === sensor._id)}
                    name="all"
                    onChange={() => selectSensor(sensor)}
                  />
                  <div className="mobile-export-item-content">
                    <div className="export-item-row">
                      <div>
                        <label className="input-label">{t("export:device_name")}</label>
                        <div>{sensor.device.name}</div>
                      </div>
                      <div>
                        <label className="input-label">{t("export:sensor_type")}</label>
                        <div>{t(`sensor_types:${getNameSlug(sensor.sensor_type)}`)}</div>
                      </div>
                    </div>
                    <Collapse isOpened={sensor._id === expandedSensorId}>
                      <div>
                        <div className="export-item-row">
                          <div>
                            <label className="input-label">{t("export:device_type")}</label>
                            <div>{sensor.device.Device_type?.name}</div>
                          </div>
                          <div>
                            <label className="input-label">{t("export:status")}</label>
                            <div>{sensor.device_status ? t("common:online") : t("common:offline")}</div>
                          </div>
                        </div>
                        <div className="export-item-row">
                          <div>
                            <label className="input-label">{t("export:sample_interval")}</label>
                            <div>
                              {sensor.sample_interval
                                ? getIntervalOptions(t).find((option) => option.value === Number(sensor.sample_interval))?.label
                                : "-"}
                            </div>
                          </div>
                          <div>
                            <label className="input-label">{t("export:transmit_interval")}</label>
                            <div>
                              {sensor.transmit_interval
                                ? getTransmitIntervalOptions(t).find((option) => option.value === sensor.transmit_interval)?.label
                                : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="export-item-row">
                          <div>
                            <label className="input-label">{t("export:approaching")}</label>
                            <div>{sensor.approaching ? t("common:yes") : t("common:no")}</div>
                          </div>
                          <div>
                            <label className="input-label">{t("export:in_alert")}</label>
                            <div>{sensor.in_alert ? t("common:yes") : t("common:no")}</div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                  <button
                    className="btn btn-primary btn-circle"
                    onClick={() => setExpandedSensorId(sensor._id === expandedSensorId ? undefined : sensor._id)}>
                    <i className={classNames("fa fa-chevron-down fa-rotate", { "fa-rotate-180": sensor._id === expandedSensorId })} />
                  </button>
                </div>
              ))}
            </Box>
            {!myFilter(sensors, getFilters()).length && <div className="export-no-results">{t("export:no_results")}</div>}
          </section>
          <div className="mobile-floating-buttons">
            <ClickAwayListener onClickAway={() => setExportMenuOpen(false)}>
              <button
                disabled={!selectedSensors.length && !myFilter(sensors, getFilters()).length}
                className={classNames("btn btn-info dropdown", { open: exportMenuOpen })}
                onClick={() => setExportMenuOpen(true)}>
                <span>{t("export:selection")}</span>
                <i className={classNames("fa fa-caret-down fa-rotate", { "fa-rotate-180": exportMenuOpen })} />
                <section role="menu" className="dropdown-menu dropdown-menu-top dropdown-arrowless" style={{ top: -90 }}>
                  <div className="export-menu-mobile">
                    <button
                      className="btn btn-plain u-color-white"
                      onClick={() => {
                        setSelectedSensors([]);
                        setExportMenuOpen(false);
                      }}>
                      {t("export:cancel_selection")}
                    </button>
                    <button
                      className="btn btn-plain u-color-white"
                      onClick={() => {
                        setSelectedSensors(myFilter(sensors, getFilters()));
                        setExportMenuOpen(false);
                      }}>
                      {t("export:select_all")}
                    </button>
                  </div>
                </section>
              </button>
            </ClickAwayListener>
            <button
              disabled={!selectedSensors.length}
              className="btn btn-primary"
              onClick={() =>
                showAppModal(
                  <ExportModal
                    savePreset={savePreset}
                    preset={preset}
                    selectedSensors={selectedSensors}
                    minDate={dateRange[0]}
                    maxDate={dateRange[1]}
                  />,
                )
              }>
              {t("export:export_selected", { count: selectedSensors.length })}
            </button>
          </div>
        </>
      ) : (
        <section className="main-panel export-main">
          <Table className="export-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <CheckboxInput
                    disabled={!myFilter(sensors, getFilters()).length}
                    checked={
                      myFilter(sensors, getFilters()).length > 0 && selectedSensors.length === myFilter(sensors, getFilters()).length
                    }
                    name="all"
                    onChange={() =>
                      setSelectedSensors(
                        selectedSensors.length === myFilter(sensors, getFilters()).length ? [] : myFilter(sensors, getFilters()),
                      )
                    }
                  />
                </TableCell>
                <TableCell>{t("export:device_name")}</TableCell>
                <TableCell>{t("export:device_type")}</TableCell>
                <TableCell>{t("export:sensor_type")}</TableCell>
                <TableCell>{t("export:status")}</TableCell>
                <TableCell>{t("export:sample_interval")}</TableCell>
                <TableCell>{t("export:transmit_interval")}</TableCell>
                <TableCell>{t("export:approaching")}</TableCell>
                <TableCell>{t("export:in_alert")}</TableCell>
                <TableCell>
                  <button
                    onClick={() =>
                      showAppModal(
                        <ExportModal
                          preset={preset}
                          savePreset={savePreset}
                          selectedSensors={selectedSensors}
                          minDate={dateRange[0]}
                          maxDate={dateRange[1]}
                        />,
                      )
                    }
                    className="btn btn-primary"
                    disabled={!selectedSensors.length}>
                    {t("export:export_button")}
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myFilter(sensors, getFilters()).map((sensor) => (
                <TableRow key={sensor._id}>
                  <TableCell>
                    <CheckboxInput
                      checked={selectedSensors.some((s) => s._id === sensor._id)}
                      name="all"
                      onChange={() => selectSensor(sensor)}
                    />
                  </TableCell>
                  <TableCell>{sensor.device.name}</TableCell>
                  <TableCell>{sensor.device.Device_type?.name}</TableCell>
                  <TableCell>{sensor.sensor_type}</TableCell>
                  <TableCell>{sensor.device_status ? t("common:online") : t("common:offline")}</TableCell>
                  <TableCell>
                    {sensor.sample_interval
                      ? getIntervalOptions(t).find((option) => option.value === Number(sensor.sample_interval))?.label
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {sensor.transmit_interval
                      ? getTransmitIntervalOptions(t).find((option) => option.value === sensor.transmit_interval)?.label
                      : "-"}
                  </TableCell>
                  <TableCell>{sensor.approaching ? t("common:yes") : t("common:no")}</TableCell>
                  <TableCell>
                    <div className="status-wrapper">
                      <div className={classNames("status-icon", sensor.in_alert ? "color-alert" : "color-success")}>•</div>
                      {sensor.in_alert ? t("common:yes") : t("common:no")}
                    </div>
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!myFilter(sensors, getFilters()).length && <div className="export-no-results">{t("export:no_results")}</div>}
        </section>
      )}
    </>
  );
};
