import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import "./ExportModal.scss";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { IDevice, IExportPreset } from "../../../Managers/Types";
import {
  FormFieldCheckbox,
  FormFieldSelect,
  FormFieldText,
  ILineChartValue,
  ISetPoint,
  TimeSeriesLineChart,
  useScreenMode,
} from "../../../Components";
import { AppState, getUserDateFormat, showAppModal, showSnackbar } from "../../../AppState";
import { getDeviceSensorData, getTransmitIntervalOptions } from "../../../Managers/DeviceService";
import {
  exportToXLSX,
  getConverts,
  getNameSlug,
  myFilter,
  prepareChartDataSet,
  prepareHistoricalData,
  readingUOMString,
  renderHeaderFooterPDF,
} from "../../../Managers";
import { Modal } from "../../../Components/Modal";
import { IReportSensor } from "../Export";
import { ModalExportTable } from "./ModalExportTable";
import { PointsOfInterestSetup } from "./PointsOfInterestSetup";
import classNames from "classnames";
import { ConfirmModal } from "../../../Modals";
import { ValidationMessages, WindowSize } from "../../../Enums";

interface IExportModalProps {
  selectedSensors: IReportSensor[] | ISensorWithExportOptionAndSetPoints[];
  minDate: Date;
  maxDate: Date;
  startingStep?: number;
  preset?: IExportPreset;
  savePreset: (presetPart: IExportPreset) => Promise<void>;
}

export interface ISensorWithExportOptionAndSetPoints extends IReportSensor {
  include_history: boolean;
  include_alert_notes: boolean;
  include_alert_protocol: boolean;
  include_graph: boolean;
  setPoints?: ISetPoint[];
  data?: ILineChartValue[];
}

interface IExportFormProps {
  filename: string;
  fileFormat: string;
  average: number;
  // interval: number;
  saveAsPreset: boolean;
  presetName: string;
  lotCode: string;
  productCode: string;
}

interface IChartDataProps {
  device: IDevice;
  sensor: ISensorWithExportOptionAndSetPoints;
  data: ILineChartValue[];
}

const IMAGE_DIMENSIONS = { width: 535, height: 200 };

export const ExportModal: React.FC<IExportModalProps> = observer(
  ({ savePreset, preset, minDate, maxDate, selectedSensors, startingStep }) => {
    const [currentStep, setCurrentStep] = useState(startingStep ?? 1);
    const [sensorsWithExportOptions, setSensorsWithExportOptions] = useState<ISensorWithExportOptionAndSetPoints[]>([]);
    const [chartsData, setChartsData] = useState<IChartDataProps[]>([]);
    const [commonTableData, setCommonTableData] = useState<IChartDataProps[]>([]);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [renderedIds, setRenderedIds] = useState<number[]>([]);
    const [chartsRendered, setChartsRendered] = useState(!sensorsWithExportOptions.some((s) => s.include_graph));
    const [sensorDataLoaded, setSensorDataLoaded] = useState(false);
    const [needReloadSensorData, setNeedReloadSensorData] = useState(true);
    const [images, setImages] = useState<Record<string, string>>({});
    const [binnedTime, setBinnedTime] = useState("-1");
    const [atLeastOneSettingSelected, setAtLeastOneSettingSelected] = useState(false);

    const { t } = useTranslation(["common", "export"]);

    const intervalOptions = useMemo(() => getTransmitIntervalOptions(t), []);

    const mode = useScreenMode();

    const STEP_COUNT = 3;

    useEffect(() => {
      if (
        sensorsWithExportOptions.filter((s) => s.include_graph).length === renderedIds.length &&
        chartsData.length === sensorsWithExportOptions.length
      ) {
        setChartsRendered(true);
      }
    }, [renderedIds, chartsData]);

    useEffect(() => {
      if (currentStep === STEP_COUNT) {
        _getSensorData(minDate, maxDate, sensorsWithExportOptions);
      } else {
        setNeedReloadSensorData(true);
      }
    }, [currentStep]);

    useEffect(
      () =>
        setSensorsWithExportOptions(
          selectedSensors.map((s) => {
            const { include_history, include_graph, include_alert_notes, include_alert_protocol } =
              s as ISensorWithExportOptionAndSetPoints;
            const sensorFromPreset = preset?.sensorConfig?.find((config) => config._id === s._id);
            return {
              ...s,
              include_history: include_history ?? sensorFromPreset?.include_history ?? false,
              include_graph: include_graph ?? sensorFromPreset?.include_graph ?? false,
              include_alert_protocol: include_alert_protocol ?? sensorFromPreset?.include_alert_protocol ?? false,
              include_alert_notes: include_alert_notes ?? sensorFromPreset?.include_alert_notes ?? false,
            };
          }),
        ),
      [],
    );

    useEffect(() => {
      setAtLeastOneSettingSelected(
        sensorsWithExportOptions.filter((s) => s.include_alert_notes || s.include_alert_protocol || s.include_history || s.include_graph)
          .length > 0,
      );
    }, [sensorsWithExportOptions]);

    useEffect(() => {
      setNeedReloadSensorData(true);
      // console.warn("from binnedtime");

      _getSensorData(minDate, maxDate, sensorsWithExportOptions, true);
    }, [binnedTime]);

    const _getSensorData = (
      start: Date,
      end: Date,
      sensors: ISensorWithExportOptionAndSetPoints[] | null,
      hardRefresh: boolean = false,
    ) => {
      if (!sensors) {
        return;
      }

      const promises: Promise<IChartDataProps>[] = [];
      setChartsData([]);
      setSensorDataLoaded(false);
      let workingSensors = sensors.filter((s) => s.include_graph);
      if (workingSensors.length < 1) {
        workingSensors = sensors;
      }
      workingSensors.forEach((sensor) => {
        const device = sensor.device;

        if (!sensor.sensor || !device || (!hardRefresh && sensor.data?.length)) {
          return;
        }

        const { convertToRh, convertToTemp } = getConverts(device);

        promises.push(
          getDeviceSensorData(sensor.sensor, start.toISOString(), end.toISOString(), true, binnedTime, hardRefresh).then((r) => {
            const processed = prepareChartDataSet(r, sensor.sensor, sensor.sensor.is_imperial, convertToRh, convertToTemp);
            return { sensor, device, data: processed } as IChartDataProps;
          }),
        );
      });
      Promise.all(promises)
        .then((result) => {
          // console.warn("result", result);
          if (hardRefresh) {
            setCommonTableData(result.filter((d) => !!d.data));
          } else {
            setCommonTableData([]);
            setChartsData(result.filter((d) => !!d.data));
          }
          setNeedReloadSensorData(false);
          setSensorDataLoaded(true);
        })
        .catch((e) => {
          const errorMessage = t("export:load_error");
          showSnackbar(errorMessage, "error");
          console.log(errorMessage, e);
        });
    };

    const saveReports = async (values: IExportFormProps) => {
      setShowLoader(true);
      const { lotCode, productCode, fileFormat, average, filename, saveAsPreset, presetName } = values;

      if (saveAsPreset) {
        try {
          const sensorConfig = sensorsWithExportOptions.map(
            ({ _id, include_graph, include_history, include_alert_notes, include_alert_protocol }) => ({
              _id,
              include_alert_notes,
              include_alert_protocol,
              include_history,
              include_graph,
            }),
          );
          await savePreset({
            sensorConfig,
            average,
            interval: intervalOptions[0].value,
            fileType: fileFormat,
            filename,
            name: presetName,
            lotCode,
            productCode,
          } as IExportPreset);
        } catch (e) {
          showSnackbar(t("export:save_preset_error"), "error");
          return;
        }
      }

      if (!AppState.appModal) {
        return;
      }
      const contentArray: Record<string, string | number | null | undefined>[] = [];
      let doc = new jsPDF("p", "pt");
      const isPdf = () => fileFormat === "pdf" || !fileFormat;

      const dataByDevice: Record<number, ISensorWithExportOptionAndSetPoints[]> = {};
      const dataForCommonTableByDevice: Record<number, ISensorWithExportOptionAndSetPoints[]> = {};
      // console.warn("sensorsWithExportOptions", sensorsWithExportOptions);

      sensorsWithExportOptions.forEach(({ device, data, sensor, ...rest }) => {
        const existingData = data?.length ? data : chartsData.find((d) => d.sensor._id === sensor._id)?.data ?? [];
        if (dataByDevice[device._id]) {
          dataByDevice[device._id].push({ data: existingData, device, sensor, ...rest });
        } else {
          dataByDevice[device._id] = [{ data: existingData, device, sensor, ...rest }];
        }
      });

      const dataByDeviceEntries = Object.entries(dataByDevice);

      for (let i = 0; i < dataByDeviceEntries.length; i++) {
        let historical: (string | number | null)[][] = [];
        const [deviceId, data] = dataByDeviceEntries[i];
        const deviceImages: string[] = [];

        if (isPdf()) {
          for (const item of data) {
            if (item.include_graph && images[item._id]) {
              deviceImages.push(images[item._id]);
            }
          }
        }

        const sensorsToExportAlerts = data.reduce((arr, item) => {
          if (item.include_history) {
            arr.push({
              id: item.sensor._id,
              include_alert_notes: item.include_alert_notes,
              include_alert_protocol: item.include_alert_protocol,
            });
          }
          return arr;
        }, [] as { id: number; include_alert_protocol: boolean; include_alert_notes: boolean }[]);

        if (!isPdf() && sensorsToExportAlerts.length) {
          const { historicalHeaders, historicalData } = await prepareHistoricalData(sensorsToExportAlerts, minDate, maxDate, t);
          historical.push([t("export:historical")]);
          historical.push(historicalHeaders.map((header) => header.header));
          historical.push(...historicalData);
        }

        const device = sensorsWithExportOptions.find((sensor) => sensor.device._id === Number(deviceId))!.device;
        const content = await generateReportForSensors(values, device, data, doc, deviceImages, historical, commonTableData);

        if (isPdf() && sensorsToExportAlerts.length) {
          const { historicalHeaders, historicalData } = await prepareHistoricalData(sensorsToExportAlerts, minDate, maxDate, t);

          if (historicalData.length) {
            doc.addPage("a4", "landscape");
            doc.setFontSize(18);
            doc.text(t("export:historical"), 40, 40);
            autoTable(doc, {
              head: [historicalHeaders.map((h) => h.header)],
              body: historicalData,
              startY: 70,
              margin: {
                top: 60,
                bottom: 70,
                left: 35,
              },
            });
          }
        }

        if (isPdf() && i !== dataByDeviceEntries.length - 1) {
          doc.addPage("a4", "portrait");
        } else if (content && Array.isArray(content)) {
          contentArray.push(...content, { [content.length]: "\n" }, { [content.length + 1]: "\n" });
        }
      }

      if (isPdf()) {
        doc.save((values.filename || t("common:untitled")) + ".pdf");
      } else {
        exportToXLSX(values.filename || t("common:untitled"), contentArray);
      }

      setChartsData([]);
      setCommonTableData([]);
      setCurrentStep(0);
      setShowLoader(false);
      showAppModal(null);
    };

    const formatTime = function (num: number) {
      if (num < 10) {
        return "0" + num;
      } else {
        return num;
      }
    };

    function formatDuration(ms: number) {
      const s1 = Math.floor(ms / 1000);
      const s2 = s1 % 60;
      const m1 = Math.floor(s1 / 60);
      const m2 = m1 % 60;
      const h1 = Math.floor(m1 / 60);
      return formatTime(h1) + "h " + formatTime(m2) + "m " + formatTime(s2) + "s";
    }

    const generateReportForSensors = async (
      values: IExportFormProps,
      device: IDevice,
      sensorData: ISensorWithExportOptionAndSetPoints[],
      doc: jsPDF,
      images: string[],
      historicalData: (string | number | null)[][] = [],
      commonTableData: IChartDataProps[] = []
    ) => {
      const { fileFormat, productCode, lotCode } = values;
      const columns = [t("export:date_time"), ...sensorData.map((d) => d.sensor.display_name + readingUOMString(device, d.sensor))];

      const dataByDate: Record<string, Record<number, string | number | null>> = {};
      const dateFormat = getUserDateFormat() + " h:mm A";

      sensorData
        .filter((data) => !data.setPoints?.length)
        .forEach((sData) =>
            {
              let commonDataExists = commonTableData.filter(t => t.sensor._id === sData.sensor._id);
              let workingTableData = commonDataExists.length ? commonDataExists[0].data : sData.data;
              return workingTableData
                  ?.filter((datapoint) => datapoint.y !== null)
                  .forEach((datapoint) => {
                    const key = moment(datapoint.x).format("YYYY-MM-DD HH:mm");
                    if (dataByDate[key]) {
                      dataByDate[key][sData.sensor._id] = datapoint.y;
                    } else {
                      dataByDate[key] = { [sData.sensor._id]: datapoint.y };
                    }
                  })
            },
        );

      const overallData: Array<Array<string | number | null>> = Object.entries(dataByDate)
        .sort((a, b) => {
          if (moment(a[0]).isBefore(moment(b[0]))) {
            return -1;
          }
          return 1;
        })
        .map(([date, value]) => {
          const values: (string | number | null)[] = [moment(date).format(dateFormat)];

          sensorData.forEach(({ sensor }) => {
            if (value[sensor._id] !== null && value[sensor._id] !== undefined) {
              values.push(value[sensor._id]);
            } else {
              values.push(null);
            }
          });

          return values;
        });

      const setPointDataEntries: { columns: string[]; data: (string | number | null)[][] }[] = [];

      sensorData
        .filter((data) => data.setPoints?.length)
        .forEach((sData) => {
          let lastReading: Date;
          let total = 0;

          const data = sData.setPoints!.map((reading) => {
            let duration = 0;

            if (lastReading) {
              // * this used to be reading.updatedAt
              duration = (reading.x || new Date()).getTime() - lastReading.getTime();
              total += duration;
            }
            // * this used to be reading.updatedAt
            lastReading = reading.x;

            return [
              reading.y ?? reading.triggeredTarget,
              reading.y,
              // * this used to be reading.updatedAt
              moment(reading.x).format(dateFormat),
              duration !== 0 ? formatDuration(duration) : "n/a",
            ];
          });
          data.push([]);
          data.push(["", "", t("export:total_elapsed_time"), formatDuration(total)]);
          setPointDataEntries.push({
            columns: [
              t("export:target_point"),
              t("export:reading") + readingUOMString(device, sData.sensor),
              t("export:date_time"),
              t("export:elapsed_time"),
            ],
            data,
          });
        });

      if (fileFormat === "pdf" || !fileFormat) {
        if (images) {
          for (const imageData of images) {
            const index = images.indexOf(imageData);
            const img = new Image();
            img.src = imageData;

            doc.setFontSize(9);
            doc.addImage({
              imageData: img,
              format: "PNG",
              x: 20,
              y: index * 250 + 120,
              width: IMAGE_DIMENSIONS.width,
              height: IMAGE_DIMENSIONS.height,
            });
          }
        }

        if (overallData.length) {
          autoTable(doc, {
            head: [columns],
            body: overallData,
            startY: images.length ? images.length * 250 + 150 : 130,
            margin: {
              top: 100,
              bottom: 70,
              left: 35,
            },
            showFoot: "everyPage",
            didDrawPage: () => renderHeaderFooterPDF(doc, device, t, lotCode, productCode),
          });
        }

        setPointDataEntries.forEach((item, index) => {
          let startY = 130;

          if (index !== 0 || overallData.length) {
            doc.addPage();
          }

          if (images.length && index === 0 && !overallData.length) {
            startY = (images.length + 1) * 150 + 50;
          }

          autoTable(doc, {
            head: [item.columns],
            body: item.data,
            startY,
            showFoot: "everyPage",
            margin: {
              top: 100,
              bottom: 70,
              left: 35,
            },
            didDrawPage: () => renderHeaderFooterPDF(doc, device, t, lotCode, productCode),
          });
        });

        return doc;
      } else {
        // Add information about device;
        overallData.unshift(columns);
        overallData.unshift([t("export:serial_number"), device.serial_number]);
        overallData.unshift([t("export:device_name"), device.name]);
        overallData.unshift([t("report_modal:lot_code"), values.lotCode ?? ""]);
        overallData.unshift([t("report_modal:product_code"), values.productCode ?? ""]);
        overallData.unshift([t("export:device_location"), device.location_note || ""]);
        overallData.unshift([]);

        const setPointData: (string | number | null)[][] = [];

        setPointDataEntries.forEach((entry) => {
          setPointData.push(entry.columns);
          setPointData.push(...entry.data);
          overallData.unshift([]);
        });

        const combinedData = [...overallData, ...setPointData, ...historicalData];

        return combinedData.map((d) =>
          d.reduce((obj, item, index) => {
            obj[index] = item;
            return obj;
          }, {} as Record<string, number | string | null | undefined>),
        );
      }
    };

    const initialValues: IExportFormProps = {
      filename: preset?.filename ?? "",
      fileFormat: preset?.fileType ?? "xlsx",
      average: preset?.average ?? -1,
      // interval: preset?.interval ?? intervalOptions[0].value,
      saveAsPreset: false,
      presetName: preset?.name ?? "",
      lotCode: preset?.lotCode ?? "",
      productCode: preset?.productCode ?? "",
    };

    const validationSchema = yup.object({
      lotCode: yup.string(),
      filename: yup.string(),
      fileFormat: yup.string(),
      // interval: yup.number(),
      average: yup.number().nullable(true),
      // .test("min", t("export:average_error"), (value, obj) => {
      //   return value === -1 || (!!value && obj.parent.interval <= (value * 3600) / 2);
      // }),
      saveAsPreset: yup.boolean(),
      presetName: yup
        .string()
        .nullable(true)
        .test("required", t(ValidationMessages.REQUIRED), (value, obj) => (obj.parent.saveAsPreset ? !!value : true)),
    });

    const onCancel = () => {
      showAppModal(
        <ConfirmModal
          onCancel={() =>
            showAppModal(
              <ExportModal
                savePreset={savePreset}
                selectedSensors={sensorsWithExportOptions}
                minDate={minDate}
                maxDate={maxDate}
                startingStep={currentStep}
              />,
            )
          }
          header={t("export:cancel_title")}
          confirmText={t("export:cancel_title")}
          onConfirm={() => showAppModal(null)}>
          {t("export:cancel_content")}
        </ConfirmModal>,
      );
    };

    const setRendered = (id: number) => {
      if (!renderedIds.includes(id)) {
        renderedIds.push(id);
        setRenderedIds(renderedIds);
      }
    };

    return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={saveReports} enableReinitialize={true}>
        {({ setFieldValue, isSubmitting, submitForm, values }) => {
          return (
            <Modal
              className={classNames(
                "export-modal",
                { "modal-el": currentStep === 1 && mode !== WindowSize.MOBILE },
                { "modal-lg": currentStep !== 1 && mode !== WindowSize.MOBILE },
              )}
              buttons={
                <div className="report-footer-buttons">
                  {currentStep < STEP_COUNT && !atLeastOneSettingSelected && (
                    <div style={{ marginBottom: 10 }}>{t("export:atleastoneselected")}</div>
                  )}
                  <div className="report-footer-buttons-main">
                    <button
                      className={classNames("btn", currentStep === 1 ? "btn-info" : "btn-plain u-text-teal u-mobile-hide")}
                      disabled={isSubmitting}
                      onClick={() => onCancel()}>
                      {t("common:close")}
                    </button>

                    {currentStep > 1 ? (
                      <button
                        className="btn btn-info"
                        disabled={isSubmitting || showLoader}
                        onClick={() => setCurrentStep(currentStep - 1)}>
                        {t("common:back")}
                      </button>
                    ) : null}

                    {currentStep < STEP_COUNT ? (
                      <button
                        className="btn btn-primary"
                        disabled={
                          isSubmitting || showLoader || (currentStep === 1 && !atLeastOneSettingSelected) || (currentStep === 2 && false)
                        }
                        onClick={() => setCurrentStep(currentStep + 1)}>
                        {t("common:next")}
                      </button>
                    ) : null}

                    {currentStep === STEP_COUNT ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => submitForm()}
                        disabled={isSubmitting || showLoader || !chartsRendered || !sensorDataLoaded}>
                        {!sensorDataLoaded ? (
                          <>
                            <i className="fa fa-spinner fa-spin" /> {t("export:loading_data")}
                          </>
                        ) : (
                          t("common:save")
                        )}
                      </button>
                    ) : null}
                  </div>
                  {currentStep > 1 ? (
                    <button
                      type="button"
                      className="btn btn-info btn-plain u-text-teal u-mobile-only"
                      disabled={isSubmitting}
                      onClick={() => onCancel()}>
                      {t("common:close")}
                    </button>
                  ) : null}
                </div>
              }
              title={
                <>
                  <span className="pull-left modal-title">{t("export:export_title", { context: currentStep })}</span>
                  <p className="pull-right">{t("export:step_of", { currentStep, totalSteps: STEP_COUNT })}</p>
                </>
              }>
              <div>
                {currentStep === 1 ? (
                  <ModalExportTable
                    setSensorsWithExportOptions={setSensorsWithExportOptions}
                    sensorsWithExportOptions={sensorsWithExportOptions}
                  />
                ) : null}

                {currentStep === 2 ? (
                  <PointsOfInterestSetup
                    savePreset={savePreset}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectedSensors={sensorsWithExportOptions}
                    saveSelectedSensors={setSensorsWithExportOptions}
                  />
                ) : null}

                {currentStep === 3 ? (
                  <div id="step3">
                    <div className="row">
                      <div className="col-md-6">
                        <FormFieldText name="filename" label={t("export:file_name")} placeholder={t("export:file_name_placeholder")} />
                      </div>
                      <div className="col-md-6">
                        <FormFieldSelect
                          options={[
                            { value: "pdf", label: "PDF" },
                            { value: "xlsx", label: "XLSX" },
                          ]}
                          name="fileFormat"
                          label={t("export:file_format")}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormFieldText name="lotCode" label={t("export:lot_code")} placeholder={t("export:lot_code_placeholder")} />
                      </div>
                      <div className="col-md-6">
                        <FormFieldText
                          name="productCode"
                          label={t("export:product_code")}
                          placeholder={t("export:product_code_placeholder")}
                        />
                      </div>
                    </div>

                    <div className="row">
                      {/* @todo to be deleted/brought back when BAPI decides on the behavior */}
                      {/*<div className="col-md-6">*/}
                      {/*  <FormFieldSelect style={{ flex: 1 }} options={intervalOptions} name="interval" label={t("export:interval")} />*/}
                      {/*</div>*/}

                      <div className="col-md-6">
                        <FormFieldSelect
                          style={{ flex: 1 }}
                          onChange={(value) => setBinnedTime(value === -1 ? "-1" : value + "h")}
                          options={[
                            { value: -1, label: t("common:unset") },
                            ...Array.from(Array(12)).map((_, i) => ({
                              value: i + 1,
                              label: t("common:hours", { count: i + 1 }),
                            })),
                          ]}
                          displayError={true}
                          name="average"
                          label={t("export:average")}
                        />
                      </div>
                    </div>
                    <FormFieldCheckbox
                      label={t("export:save_preset")}
                      checked={values.saveAsPreset}
                      name="saveAsPreset"
                      onChange={(e) => setFieldValue("saveAsPreset", e.target.checked)}
                    />

                    <div className="row">
                      <div className="col-md-6">
                        {values.saveAsPreset ? (
                          <FormFieldText
                            hint={
                              preset
                                ? {
                                    message: t("export:overwrite_warning"),
                                    className: "u-text-warning",
                                  }
                                : undefined
                            }
                            name="presetName"
                            displayError={true}
                            placeholder={t("export:preset_name_placeholder")}
                          />
                        ) : null}
                      </div>
                    </div>

                    {sensorDataLoaded &&
                      !needReloadSensorData &&
                      values.fileFormat === "pdf" &&
                      myFilter(sensorsWithExportOptions, [
                        {
                          prop: "include_graph",
                          value: true,
                        },
                      ]).map((item, i) => {
                        if (!renderedIds.includes(item._id)) {
                          return (
                            <div
                              key={item._id}
                              style={{
                                height: IMAGE_DIMENSIONS.height * 2,
                                position: "fixed",
                                width: IMAGE_DIMENSIONS.width * 2,
                                left: -9000 - 400 * i,
                              }}>
                              <div className="graph-holder">
                                <TimeSeriesLineChart
                                  height={IMAGE_DIMENSIONS.height * 2}
                                  width={IMAGE_DIMENSIONS.width * 2}
                                  data={item.data?.length ? item.data : chartsData.find((d) => d.sensor._id === item._id)?.data ?? []}
                                  bindToSuffix={`${item._id}`}
                                  setPoints={item.setPoints}
                                  onExport={(imgData) => {
                                    images[item._id] = imgData;
                                    setImages(images);
                                  }}
                                  title={t(`sensor_types:${getNameSlug(item.sensor.Sensor_type.name)}`)}
                                  onRendered={() => setRendered(item._id)}
                                />
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                ) : null}
              </div>
              {showLoader ? (
                <div className="generate-loader">
                  <i className="fa fa-spin fa-circle-o-notch" />
                </div>
              ) : null}
            </Modal>
          );
        }}
      </Formik>
    );
  },
);
