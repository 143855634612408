import moment from "moment";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import { CircularProgress, Tabs } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  ErrorBoundary,
  FormFieldDate,
  FormFieldSelect,
  ILineChartValue,
  Tab,
  TimeSeriesLineChart,
  useScreenMode,
  ZoomDates,
} from "../../../Components";
import { getDeviceSensorData, updateSensor, useDevice } from "../../../Managers/DeviceService";
import * as Measurements from "../../../Managers/MeasurementService";
import { measurementTransform } from "../../../Managers/MeasurementService";
import {
  checkForWai418HumidityDevice,
  checkForWai418TemperatureDevice,
  formatDate,
  getNameSlug,
  myIcon,
  prepareChartDataSet,
} from "../../../Managers";
import { unitsTransform } from "../../../Managers/UnitsService";
import { EditAlertsList } from "./EditAlertsList";
import { showAppModal, showSnackbar } from "../../../AppState";
import { AddAlertModal } from "./AddAlertModal";
import "./DeviceDetailModal.scss";
import { ConfirmModal } from "../../../Modals";
import { deleteSensorAlerts } from "../../../Managers/DeviceAlertService";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";
import { OrderBy, WindowSize } from "../../../Enums";
import { ISensor } from "../../../Managers/Types";

interface IDeviceDetailProps {
  isWai418?: boolean;
  deviceId: number;
  sensorIndex?: number;
  refresh?: (order?: OrderBy, sort?: string) => void;
}

export const DeviceDetailModal: React.FC<IDeviceDetailProps> = observer(({ deviceId, isWai418, sensorIndex, refresh }) => {
  const deviceQuery = useDevice(deviceId);
  const device = deviceQuery.data;

  const [selectedSensorIndex, setSelectedSensorIndex] = useState(sensorIndex ?? 0);
  const [chartData, setChartData] = useState<ILineChartValue[]>([]);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(moment().subtract(24, "hours").toDate());
  const [convertToHumidity, setConvertToHumidity] = useState(false);
  const [convertToTemperature, setConvertToTemperature] = useState(false);
  const [initStartDate, setInitStartDate] = useState(new Date());
  const [initEndDate, setInitEndDate] = useState(new Date());
  const [initSelectedSensor, setInitSelectedSensor] = useState<ISensor>();
  const mode = useScreenMode();

  const { t } = useTranslation(["dashboard", "sensor_types", "common"]);
  const hasTimeSelector = true;

  const filteredSensors =
    device?.Sensors.filter((sensor) => sensor.Sensor_type.name !== "Battery Voltage" && sensor.Sensor_type.name !== "Signal Strength").sort(
      (sensor1, sensor2) => {
        if (sensor1.Sensor_type.name === "Temperature") {
          return -1;
        } else if (sensor2.Sensor_type.name === "Temperature") {
          return 1;
        } else if (sensor1.Sensor_type.name === "Humidity") {
          return -1;
        } else if (sensor2.Sensor_type.name === "Humidity") {
          return 1;
        }
        return 0;
      },
    ) || [];

  const minDate = new Date(device?.createdAt || "");
  const selectedSensor = filteredSensors[selectedSensorIndex];

  let convertToRh = false;
  let convertToTemp = false;

  useEffect(() => {
    let needRefreshData = false;
    if (initStartDate !== startDate) {
      setInitStartDate(startDate);
      needRefreshData = true;
    }
    if (initEndDate !== endDate) {
      setInitEndDate(endDate);
      needRefreshData = true;
    }
    if (initSelectedSensor?._id !== selectedSensor?._id) {
      setInitSelectedSensor(selectedSensor);
      needRefreshData = true;
    }
    if (!needRefreshData) {
      // console.warn('skip refreshing')
      return;
    }
    // console.warn('do the refresh')
    // moved this, so we can check for wai418 devices even if the graphs fail to load the latest data
    // i.e. if the device hasn't reported in some time, this will still convert the values.
    if (device?.serial_number) {
      if (checkForWai418TemperatureDevice(device.serial_number)) {
        console.log("setting convert to temp to true");
        convertToTemp = true;
        setConvertToTemperature(convertToTemp);
      } else if (checkForWai418HumidityDevice(device.serial_number)) {
        console.log("setting convert to humidity to true");
        convertToRh = true;
        setConvertToHumidity(convertToRh);
      } else {
        console.log("not a wai418 device - 0");
      }
    } else {
      console.log("not a wai418 device - 1");
    }
    //('Params changed, loading new sensor data', { startDate, endDate, selectedSensorIndex }, toJS(selectedSensor));

    setIsGraphLoading(true);
    setChartData([]);
    let startTime = startDate.toISOString();
    let endTime = endDate.toISOString();

    selectedSensor &&
      getDeviceSensorData(selectedSensor, startTime, endTime, hasTimeSelector)
        .then((r) => {
          // console.log('data', r);
          // const processed = prepareChartDataSet(r, sensor);

          if (r && r.length) {
            // let sensorData = transformSensorData(r, selected);
            console.log("double checking if isWai is true: ", isWai418);
            console.log("double checking if device is populated: ", device?.serial_number);
            const processed = prepareChartDataSet(r, selectedSensor, selectedSensor.is_imperial, convertToRh, convertToTemp);
            // console.log('processed', processed);
            setChartData(processed);
          }
        })
        .catch((e) => {
          const errorMessage = "Error loading sensor data";
          showSnackbar(errorMessage, "error");
          console.log(errorMessage, e);
        })
        .finally(() => {
          setIsGraphLoading(false);
        });
  }, [startDate, endDate, selectedSensor]);
  // console.warn('this is changed dnkt why', startDate, endDate, selectedSensor)

  const addAlert = (alerts: any[], sensorTypeId: number) => {
    showAppModal(<AddAlertModal tabIndex={selectedSensorIndex} deviceId={deviceId} alerts={alerts} sensorTypeId={sensorTypeId} />);
  };

  const handleRemoveAllAlertsForSensor = (sensorId: number) =>
    deleteSensorAlerts(deviceId, sensorId)
      .then(() => showSnackbar(t("dashboard:device_detail.remove_alerts_success"), "success"))
      .catch((e) => {
        const errorMessage = t("dashboard:device_detail.remove_alerts_error");
        showSnackbar(errorMessage, "error");
        console.warn(errorMessage, e);
      })
      .finally(() => {
        showAppModal(<DeviceDetailModal deviceId={deviceId} sensorIndex={selectedSensorIndex} />);
      });

  const removeAllAlertsForSensor = (alerts: any[], sensorId: number) => {
    showAppModal(
      <ConfirmModal
        header={t("dashboard:device_detail.remove_all_alerts")}
        children={<p>{t("dashboard:device_detail.remove_all_alerts_confirm", { count: alerts.length })}</p>}
        confirmText={t("dashboard:device_detail.remove_all_alerts")}
        onConfirm={() => handleRemoveAllAlertsForSensor(sensorId)}
        onCancel={() => showAppModal(<DeviceDetailModal deviceId={deviceId} sensorIndex={selectedSensorIndex} />)}
      />,
    );
  };

  // TODO: Slice the data set by start/end date.
  const initialValues = {
    startDate,
    endDate,
    isImperial: selectedSensor?.is_imperial ?? true,
  };

  const setDates = (start: Date, end: Date, setFieldValue: any) => {
    handleStartDateChange(start, setFieldValue);
    handleEndDateChange(end, setFieldValue);
  };

  const handleStartDateChange = (d: Date, setFieldValue: any) => {
    setStartDate(d);
    setFieldValue("startDate", d);
  };

  const handleEndDateChange = (d: Date, setFieldValue: any) => {
    setEndDate(d);
    setFieldValue("endDate", d);
  };

  const isTemperature = useCallback(() => {
    const value = selectedSensor?.default_unit?.toLowerCase();
    return Measurements.getType(value).includes("temperature") || value === "kpa" || value === "kilopascal";
  }, [selectedSensor]);

  const getMeasurementOptions = useCallback(
    () =>
      [true, false].map((isImperial) => {
        return {
          value: isImperial,
          label: unitsTransform(selectedSensor?.default_unit || "degF", [
            selectedSensor?.default_unit || "degF",
            isImperial,
            selectedSensor?.Sensor_type.type,
          ]),
        };
      }),
    [isTemperature, selectedSensor],
  );

  const handleUnitChange = useCallback(
    (isImperial: boolean, setFieldValue: any) => {
      setFieldValue("isImperial", isImperial);
      if (device && selectedSensor) {
        updateSensor(device, { ...selectedSensor, is_imperial: isImperial }).then(() => (refresh ? refresh() : ""));
      }
    },
    [device, selectedSensor],
  );

  const displaySensorReadingWithUnit = (selectedSensor: ISensor) => {
    if (selectedSensor.Sensor_type.type === "BOOLEAN") {
      return "";
    }

    return selectedSensor.display_unit !== "" && selectedSensor.display_unit !== selectedSensor.default_unit
      ? selectedSensor.display_unit
      : unitsTransform(selectedSensor.default_unit, [
          selectedSensor.default_unit,
          selectedSensor.is_imperial,
          selectedSensor.Sensor_type.type,
          convertToHumidity,
          convertToTemperature,
        ]);
  };

  return (
    <ErrorBoundary>
      <Formik key={selectedSensor?._id} initialValues={initialValues} onSubmit={() => {}}>
        {({ setFieldValue }) => (
          <Modal
            className="device-detail-modal modal-lg"
            buttons={
              <button className="btn btn-primary" onClick={() => showAppModal(null)}>
                {t("common:close")}
              </button>
            }
            title={
              device ? (
                <>
                  <h4 className="modal-title pull-left">{device.name}</h4>
                  <span className="pull-right serial-number">
                    {t("dashboard:device_detail.serial_number")}: {device.serial_number}
                  </span>
                </>
              ) : null
            }>
            <Tabs
              variant="scrollable"
              className="modal-tabs"
              scrollButtons={mode !== WindowSize.DESKTOP}
              allowScrollButtonsMobile
              value={selectedSensorIndex}
              onChange={(_e, value) => setSelectedSensorIndex(value)}>
              {filteredSensors.map((sensor, index) => {
                const title = convertToHumidity
                  ? t("sensor_types:humidity")
                  : convertToTemperature
                  ? t("sensor_types:temperature")
                  : sensor.display_name || t(`sensor_types:${getNameSlug(sensor.Sensor_type.name)}`);
                const icon = (
                  <span>
                    {!isWai418 ? (
                      <i className={`icon icon-${myIcon(sensor.default_unit === "%" ? "set_point" : sensor.Sensor_type.name)}`} />
                    ) : null}
                  </span>
                );
                return (
                  <Tab
                    value={index}
                    label={
                      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <span>{icon}</span> <span>{title}</span>
                      </div>
                    }
                  />
                );
              })}
            </Tabs>
            {selectedSensor && (
              <div className="tab-pane active">
                <div className="row">
                  <div className="row reading-row col-sm-4">
                    <div className="col-xs-9 col-sm-12">
                      <p className="reading-title">{t("dashboard:device_detail.last_reading")}</p>
                      <div className="holder reading">
                        <p className="reading-time">{formatDate(selectedSensor.last_report_time)}</p>

                        <h5 className="reading-value">
                          {measurementTransform(selectedSensor.current_value, {
                            unit: selectedSensor.default_unit,
                            empirical: selectedSensor.is_imperial,
                            type: selectedSensor.Sensor_type.type,
                            disableRounding: false,
                            convertToHumidity: convertToHumidity,
                            convertToTemperature: convertToTemperature,
                          })}
                          <span className="sub">{displaySensorReadingWithUnit(selectedSensor)}</span>
                        </h5>
                      </div>
                    </div>
                    {isTemperature() && (
                      <div className="col-xs-3 col-sm-4 u-mobile-only">
                        <p className="reading-title">{t("dashboard:device_detail.measurement")}</p>
                        <FormFieldSelect
                          options={getMeasurementOptions()}
                          name="isImperial"
                          className="mobile-unit"
                          onChange={(v) => handleUnitChange(v, setFieldValue)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-sm-8">
                    <div className="alert-header">
                      <p className="reading-title pull-left">{t("dashboard:device_detail.edit_alerts")}</p>
                      <button
                        className="btn btn-plain pull-right add-alert-button u-text-teal"
                        onClick={() => addAlert(selectedSensor.Alerts, selectedSensor.SensorTypeId)}>
                        <i className="fa fa-plus-circle u-text-teal" />
                        <div className="u-text-teal">{t("dashboard:device_detail.add_alert")}</div>
                      </button>
                    </div>
                    <div className="alert-section">
                      {selectedSensor.Alerts?.filter((a) => !a.hidden).length > 0 ? (
                        <EditAlertsList alerts={selectedSensor.Alerts?.filter((a) => !a.hidden)} deviceId={deviceId} />
                      ) : (
                        <p>{t("dashboard:device_detail.no_alerts_configured")}</p>
                      )}
                    </div>
                    {selectedSensor.Alerts?.filter((a) => !a.hidden).length > 0 ? (
                      <button
                        style={{ padding: "4px 6px" }}
                        className="btn btn-sm btn-danger pull-right"
                        onClick={() =>
                          removeAllAlertsForSensor(
                            selectedSensor.Alerts?.filter((a) => !a.hidden),
                            selectedSensor._id,
                          )
                        }>
                        {t("dashboard:device_detail.remove_all_alerts_button").toUpperCase()}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "7px", marginBottom: 0 }}>
                  <div className="col-sm-2 u-mobile-hide" style={{ marginBottom: 15 }}>
                    <label htmlFor="zoom" className="input-label u-mobile-hide">
                      {t("common:zoom")}
                    </label>
                    <ZoomDates onSelectOption={(start, end) => setDates(start, end, setFieldValue)} />
                  </div>

                  <FormFieldDate
                    name="startDate"
                    minDate={minDate}
                    maxDate={endDate}
                    hasTimeSelector={hasTimeSelector}
                    className="col-xs-6 col-sm-4 header-tool"
                    onChange={(d) => handleStartDateChange(d, setFieldValue)}
                    label={t("common:from")}
                  />

                  <FormFieldDate
                    name="endDate"
                    minDate={startDate}
                    maxDate={new Date()}
                    hasTimeSelector={hasTimeSelector}
                    className="col-xs-6 col-sm-4"
                    onChange={(d) => handleEndDateChange(d, setFieldValue)}
                    label={t("common:to")}
                  />
                  {isTemperature() && (
                    <FormFieldSelect
                      options={getMeasurementOptions()}
                      name="isImperial"
                      className="col-xs-6 col-sm-2 u-mobile-hide"
                      label={t("dashboard:device_detail.measurement")}
                      onChange={(v) => handleUnitChange(v, setFieldValue)}
                    />
                  )}
                </div>

                <div className="holder">
                  {isGraphLoading ? (
                    <CircularProgress />
                  ) : (
                    <TimeSeriesLineChart
                      data={chartData}
                      setPoints={[]}
                      valueName={
                        convertToHumidity
                          ? t("sensor_types.humidity")
                          : convertToTemperature
                          ? t("sensor_types.temperature")
                          : t(`sensor_types:${getNameSlug(selectedSensor.Sensor_type.name)}`)
                      }
                    />
                  )}
                </div>
                {!isGraphLoading && chartData.length ? (
                  <div className="u-text-small graph-break-note">{t("dashboard:device_detail.graph_break_note")}</div>
                ) : null}
                <div className="col-12 u-mobile-only" style={{ marginTop: 8 }}>
                  <ZoomDates onSelectOption={(start, end) => setDates(start, end, setFieldValue)} />
                </div>
              </div>
            )}
          </Modal>
        )}
      </Formik>
    </ErrorBoundary>
  );
});
