import Moment from "moment";
import moment from "moment";
import * as Measurements from "./MeasurementService";
import { IAlert, IDevice, IDeviceBond, IDeviceTypeGroup, ISensor } from "./Types";
import * as Units from "./UnitsService";
import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";
import { TFunction } from "i18next";
import { isUserRoleAllowed, UserRoles } from "../Enums";
import { AppState } from "../AppState";
import { ISensorType } from "./Alert.model";

const API_PATH = "api/devices";
const QK_DEVICES = ["DEVICES"];
const QK_SENSORS = ["SENSORS"];
const QK_DEVICE_TYPE_GROUPS = ["DEVICE_TYPE_GROUPS"];
const QK_DEVICE_BONDS = ["DEVICE_BONDS"];

// NOTE: To understand react-query a bit see https://tkdodo.eu/blog/effective-react-query-keys.
// It uses 'query keys' to identify queries to clear, eliminating most data store caching work.
// If you use an array as a query key e.g. ['DEVICES', 3] you can selectively update or invalidate
// queries via the hierarchy. So you can "get" ['DEVICES'] and it will cache the whole list. Then
// you can "get" ['DEVICES', 3] and it will immediately return device 3 while revalidating that
// piece of data if needed. You can then deliberately invalidate ['DEVICES', 3] to mark just that
// one device stale, or ['DEVICES'] to re-query them all.

export const getDevices = (sensors: boolean, alerts: boolean) =>
  Endpoint.get<IDevice[]>(`${API_PATH}?sensors=${sensors}&alerts=${alerts}`).then((r) => r.data);

export const useDevices = (sensors: boolean, alerts: boolean) =>
  useQuery([...QK_DEVICES, { sensors, alerts }], () => getDevices(sensors, alerts), { refetchInterval: 60000 });

export const useDevicesWithProps = (props: Array<keyof IDevice>, sensors = false, deviceType = false, deviceSettings = false) =>
  useQuery([...QK_DEVICES, props, sensors, deviceType, deviceSettings], () =>
    Endpoint.get<IDevice[]>(`${API_PATH}?props=${props}&sensors=${sensors}&deviceType=${deviceType}&deviceSettings=${deviceSettings}`).then(
      (r) => r.data,
    ),
  );

export const getDevice = (id: number) => Endpoint.get<IDevice>(`${API_PATH}/${id}?sensors=true&alerts=true`).then((r) => r.data);

export const useDevice = (id: number) => useQuery([...QK_DEVICES, id], () => getDevice(id));

export const createDevice = (device: IDevice) =>
  Endpoint.post(`${API_PATH}`, device).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const updateDevice = (device: IDevice) =>
  Endpoint.put(`${API_PATH}/${device._id}`, device).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const updateDevices = (devices: IDevice[]) =>
  Endpoint.put(`${API_PATH}`, devices).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const removeDevice = (device: IDevice) =>
  Endpoint.delete(`${API_PATH}/${device._id}`).then((r) => {
    invalidateQuery(QK_DEVICES);
    return r.data;
  });

export const updateSensor = (device: IDevice, sensor: ISensor) =>
  Endpoint.put(`${API_PATH}/${device._id}/sensors/${sensor._id}`, sensor).then((r) => {
    invalidateQuery(QK_DEVICES);
    invalidateQuery(QK_SENSORS);
    return r.data;
  });

export const getDeviceTypeGroups = (gatewayIds?: number[], locationId?: number) => {
  let url = "api/device_type_groups";

  if (locationId) {
    url += "?locationId=" + locationId;
  }
  if (gatewayIds && gatewayIds.length > 0) {
    url += "&gatewayIds=" + gatewayIds;
  }
  return Endpoint.get<IDeviceTypeGroup[]>(url).then((r) => {
    invalidateQuery(QK_DEVICES);
    invalidateQuery(QK_DEVICE_TYPE_GROUPS);
    return r.data;
  });
};

export const getDeviceBonds = (deviceId: number) =>
  Endpoint.get<IDeviceBond[]>(`/api/gateway_devices/device/${deviceId}`).then((r) => {
    invalidateQuery(QK_DEVICE_BONDS);
    return r.data;
  });

export const deleteDeviceBond = (deviceBond: IDeviceBond) =>
  Endpoint.delete("/api/gateway_devices/", { data: deviceBond }).then((r) => {
    invalidateQuery(QK_DEVICE_BONDS);
    return r.data;
  });

// TODO: Invalidate this query when creating or editing groups
export const useDeviceTypeGroups = (gatewayIds?: number[], locationId?: number) =>
  useQuery("QK_DEVICE_TYPE_GROUPS" + gatewayIds?.join(",") + locationId, () => getDeviceTypeGroups(gatewayIds, locationId));

export const getSensorData = (sensor: ISensor, startDate: any, endDate: any) => {
  let min, max;
  let data: any[] = [];
  let sensorType = sensor.Sensor_type;

  let range = sensorType.ranges as any;

  range = range[Object.keys(range)[0]];
  min = range[0];
  max = range[1];

  let value;

  startDate = Moment(startDate);
  endDate = Moment(endDate);

  if (sensorType && sensorType.type === "RANGE") {
    while (startDate < endDate) {
      value = Math.random() * (max - min) + min;
      data.push({
        // * This used to be "updatedAt"
        createdAt: startDate.toISOString(),
        value,
      });

      startDate.add(5, "minutes");
    }
  }

  return data;
};

export interface ISensorDatapoint {
  _id: string; // "1"
  SensorId: number; // 2875
  createdAt: string; // "2022-03-02T02:50:00.000Z"
  value?: {
    value: number; // 2.78
  };
}

export interface ISensorDatapointStaleHistory extends ISensorDatapoint {
  updatedAt: string;
  is_finished: boolean;
  ignore?: boolean;
}

export const getDeviceSensorData = (sensor: ISensor, startDate: string, endDate: string, hasTimeSelector: boolean, binnedTime?: string, withoutGaps: boolean = false) => {
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);
// console.warn('withoutGaps', withoutGaps)
  const daysBetween = momentEndDate.diff(momentStartDate, "days");

  return Promise.all([
    Endpoint.get<ISensorDatapoint[]>(
      `api/sensors/${sensor._id}/data?startDate=${startDate}&endDate=${endDate}&deviceId=${sensor.DeviceId}&hasTimeSelector=${
        hasTimeSelector ? hasTimeSelector : ""
      }&binnedTime=${binnedTime || ""}&withoutGaps=${withoutGaps ? withoutGaps : ""}`,
    ),
    Endpoint.get<ISensorDatapointStaleHistory[]>(
      `api/sensor_stale_histories/sensorStaleHistory/${sensor._id}?startDate=${startDate}&endDate=${endDate}`,
    ),
  ]).then((r) => {
    const staleResponse: ISensorDatapointStaleHistory[] = r[1].data;
    let dataResponse: ISensorDatapoint[] = r[0].data;
    let blankPoints: ISensorDatapoint[] = [];
    let sampleInterval = Number(sensor.Device?.Device_setting?.settings_data.sampleInterval ?? 300);
    if (daysBetween > 2 && daysBetween <= 13) {
      sampleInterval = 15 * 60; //15m
    } else if (daysBetween > 13) {
      sampleInterval = 60 * 60; //1h
    }

    dataResponse.forEach((dataPoint, pointIdx) => {
      let intercepted = false;
      staleResponse.forEach((stalePoint: ISensorDatapointStaleHistory, staleIdx) => {
        if (
          moment(dataPoint.createdAt).isAfter(moment(stalePoint.createdAt)) &&
          moment(dataPoint.createdAt).isBefore(moment(stalePoint.updatedAt))
        ) {
          intercepted = true;
          staleResponse[staleIdx].ignore = true;
        }
      });
      if (
        intercepted &&
        dataResponse[pointIdx - 1] &&
        moment(dataPoint.createdAt).diff(dataResponse[pointIdx - 1]?.createdAt, "seconds") > sampleInterval * 1.2
      ) {
        blankPoints.push({ createdAt: dataResponse[pointIdx - 1]?.createdAt } as ISensorDatapoint);
      }
    });

    // if sensor is still not sending data
    const stillStale = staleResponse.find((sh) => !sh.is_finished);
    if (!!stillStale) dataResponse = dataResponse.filter((point) => new Date(point.createdAt) < new Date(stillStale.createdAt));

    return [
      ...dataResponse,
      ...blankPoints,
      ...staleResponse
        .filter((el) => !el.ignore)
        .map((p) => {
          return { createdAt: p.createdAt } as ISensorDatapoint;
        }),
    ];
  });
};

export interface ISensorReading {
  value: string;
  createdAt: Date;
  unit: string;
}

export const transformSensorData = (data: any[], sensor: any): ISensorReading[] => {
  if (!sensor.Sensor_type) {
    return data;
  }
  let unit = Units.transform(sensor.default_unit, [sensor.default_unit, sensor.Sensor_type.type]);

  // hard override of the unit if it's not the default
  if (sensor.default_unit !== sensor.display_unit) {
    unit = sensor.display_unit;
  }

  return data.map((sensorData) => {
    return {
      value: Measurements.transform(sensorData.value.value, { unit: sensor.default_unit }),
      // * This used to be "updatedAt"
      createdAt: new Date(sensorData.createdAt),
      unit,
    };
  });
};

export const calcBatteryStrength = (battery: number, base?: number): number => {
  let maxNum = 3.6;
  let minNum = 2.5;

  base = base ? base : 4;
  let percentage = Math.round(((battery - minNum) / (maxNum - minNum)) * base);

  if (!percentage || percentage < 0) {
    percentage = 0;
  }

  return Math.min((percentage * 100) / base, 100);
};

export const calcSignalStrength = (strength: number, base?: number): number => {
  let maxNum = -10;
  let minNum = -95;
  base = base ? base : 5;
  let percentage = Math.round(((strength - minNum) / (maxNum - minNum)) * base);

  if (!percentage || percentage < 0) {
    percentage = 0;
  }

  return Math.min((percentage * 100) / base, 100);
};

export const getDeviceState = (device: IDevice) => {
  let deviceCondition: string | undefined;
  const states: Record<number, { type: string; alert: IAlert }> = {};

  device.Sensors.forEach((sensor) => {
    let condition: any;
    let alerts = sensor.Alerts;
    alerts.forEach((alert) => {
      const sensorAlert = alert.Sensor_alerts;

      if (sensorAlert && sensorAlert.is_active) {
        if ((sensorAlert.condition === "WARNING" && !condition) || sensorAlert.condition === "ALERT") {
          condition = {
            type: alert.Sensor_alerts.condition,
            alert,
          };
        }
      }
    });

    if (condition) {
      states[sensor._id] = condition;

      if ((condition.type === "WARNING" && !deviceCondition) || condition?.type === "ALERT") {
        deviceCondition = condition?.type || "";
      }
    }
  });

  return { deviceCondition, states };
};

export const filterIntervalOptions = (options: Array<{ value: number; label: string }>) => {
  if (isUserRoleAllowed(UserRoles.APP_ADMIN) || AppState.user?.Account?.is_demo) {
    // skip limits
    return options;
  }
  const transmissionRateMinutesLimit = AppState.user?.Limits?.limits.TRANSMISSION ?? 5;
  return options.filter((v) => v.value >= transmissionRateMinutesLimit * 60);
};

export const getTransmitIntervalOptions = (t: TFunction) => {
  const values = [
    { value: 60, label: t("common:minutes", { count: 1 }) },
    { value: 2 * 60, label: t("common:minutes", { count: 2 }) },
    { value: 3 * 60, label: t("common:minutes", { count: 3 }) },
    { value: 4 * 60, label: t("common:minutes", { count: 4 }) },
    { value: 5 * 60, label: t("common:minutes", { count: 5 }) },
    { value: 10 * 60, label: t("common:minutes", { count: 10 }) },
    { value: 15 * 60, label: t("common:minutes", { count: 15 }) },
    { value: 20 * 60, label: t("common:minutes", { count: 20 }) },
    { value: 25 * 60, label: t("common:minutes", { count: 25 }) },
    { value: 30 * 60, label: t("common:minutes", { count: 30 }) },
    { value: 60 * 60, label: t("common:hours", { count: 1 }) },
    { value: 6 * 60 * 60, label: t("common:hours", { count: 6 }) },
    { value: 12 * 60 * 60, label: t("common:hours", { count: 12 }) },
  ];

  return filterIntervalOptions(values);
};

const getGatewaySensorTypes = (gatewayId?: number, groupId?: number) => {
  let url = "api/sensor_types?";
  if (gatewayId) {
    url += "&gateway_id=" + gatewayId;
  }
  if (groupId) {
    url += "&group_id=" + groupId;
  }
  return Endpoint.get(url).then((r) => r.data);
};

export const useGatewaySensorTypes = (gatewayId?: number, groupId?: number) =>
  useQuery<ISensorType[]>(["SENSORGATEWAYTYPES" + gatewayId + groupId], () => getGatewaySensorTypes(gatewayId, groupId));

export const getIntervalOptions = (t: TFunction) =>
  filterIntervalOptions([
    { value: 60, label: t("common:minutes", { count: 1 }) },
    { value: 5 * 60, label: t("common:minutes", { count: 5 }) },
  ]);
