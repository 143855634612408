import * as yup from "yup";
import { Formik } from "formik";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  addLocation,
  createLocationSharedUser,
  deleteLocation,
  getLocationWithUsers,
  updateLocation,
  updateLocationSharedUser,
  updateLocationUsers,
} from "../Managers/LocationService";
import { CountrySelect, FormFieldCheckbox, FormFieldSelect, FormFieldText, ICON_TOOLTIP_DEFAULT_DELAY, StyledTooltip } from "../Components";
import { ICountry, isLocalWAM, mySearch } from "../Managers";
// TODO: The old code loaded states/timezone constants but then didn't appear to use them
import { Timezone } from "../Managers/timezone.const";
import { ILocation, ILocationDetail, IUser } from "../Managers/Types";
import { useUsers } from "../Managers/UsersService";
import { useCountries, useLocations } from "../Managers/API";
import { showAppModal, showSnackbar } from "../AppState";
import { ConfirmModal } from "./index";
import "./LocationsModal.scss";
import { Modal } from "../Components/Modal";
import { useTranslation } from "react-i18next";
import { ValidationMessages } from "../Enums";
import { PaginationButtons } from "../Components/PaginationButtons";
import { XIcon } from "@heroicons/react/solid";
import { TrashIcon } from "../icon/trash";

interface ILocationFormProps
  extends Omit<
    ILocationDetail,
    | "address"
    | "country"
    | "shared_user_id"
    | "sharedUserId"
    | "createdAt"
    | "updatedAt"
    | "AccountId"
    | "lowBatteryCount"
    | "Gateways"
    | "zip"
  > {
  street1: string;
  street2: string;
  country: ICountry;
  zip: string;
}

export const LocationsModal: React.FC<{ loc?: ILocation | null }> = observer(({ loc }) => {
  const countryList = useCountries();
  const countries = countryList.data ?? [];

  const [mode, setMode] = useState<"editLocation" | "createLocation" | "createLocationMobile">(loc ? "editLocation" : "createLocation"); // mode = locations.length === 0 ? 'createLocation' : 'editLocation';
  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(loc || null);
  const [isSaving, setIsSaving] = useState(false);
  const [allocatedUsers, setAllocatedUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [paginatedFilteredUsers, setPaginatedFilteredUsers] = useState<IUser[]>([]);
  const [country, setCountry] = useState<ICountry>(countries.find((c) => c.code === "US")!);
  const [searchLocationString, setSearchLocationString] = useState("");
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [sortedLocations, setSortedLocations] = useState<ILocation[]>([]);
  const [searchUserString, setSearchUserString] = useState("");

  const locations = useLocations();
  const users = useUsers();

  const { t } = useTranslation(["locations", "common"]);

  const numberOfUsersPerPaginatedPage = 8;

  const timeZoneOptions = [
    { value: "", label: `-- ${t("common:select")} --` },
    ...Timezone.map((tz) => ({ value: `${tz.offset}`, label: `${tz.value} - (UTC${tz.offset})` })),
  ];

  const getDefaultCountry = () => countries.find((c) => c.code === "US")!;

  const validationSchema = yup.object({
    city: yup.string(),
    country: yup.object(),
    name: yup.string().required(t(ValidationMessages.REQUIRED)),
    phone: yup.string().matches(/^[0-9]+$/, t(ValidationMessages.PHONE)),
    sharedUserName: yup.string().min(1),
    sharedUserPassword: yup.string().min(8),
    street1: yup.string().matches(/[^,]+$/, t("locations:wrong_address")),
    street2: yup.string().matches(/[^,]+$/, t("locations:wrong_address")),
    state: yup.string(),
    timezone: yup.number(),
    Users: yup.array(),
    zip: yup.string(),
  });

  useEffect(() => {
    if (users.data) {
      const filtered = [...users.data].sort((a, b) => {
        let nameA = a.first_name + a.last_name;
        let nameB = b.first_name + b.last_name;

        return nameA > nameB ? 1 : -1;
      });

      const searchFiltered = filtered.filter(
        (user) =>
          user.first_name?.toLowerCase().includes(searchUserString.toLowerCase()) ||
          user.last_name?.toLowerCase().includes(searchUserString.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchUserString.toLowerCase()),
      );

      setFilteredUsers(searchFiltered);
    }
  }, [users.data, searchUserString]);

  useEffect(() => {
    const sortedLocations = mySearch<ILocation>([...(locations.data ?? [])], searchLocationString);
    sortedLocations.sort((a, b) =>
      order === "asc" ? a.name.toLowerCase().localeCompare(b.name.toLowerCase()) : b.name.toLowerCase().localeCompare(a.name.toLowerCase()),
    );
    setSortedLocations(sortedLocations);
  }, [locations.data, searchLocationString, order]);

  const selectLocation = (l: ILocation | null) => {
    if (l == null) {
      setSelectedLocation(null);
      setMode("createLocation");
      return;
    }

    setMode("editLocation");
    setIsSaving(false);
    getLocationWithUsers(l)
      .then((r) => {
        setAllocatedUsers([...(r.Users ?? [])]);
        setSelectedLocation(r);
        if (r.country) {
          setCountry(r.country ? countries.find((c) => c.code === r.country) ?? getDefaultCountry() : getDefaultCountry());
        }
      })
      .catch((e) => {
        console.log(e);
        showSnackbar(t("locations:load_error"), "error");
      });
  };

  const showDeleteConfirm = () => {
    // TODO: Make sure we can "flip" like this without losing our context
    const locationToDelete = selectedLocation;
    showAppModal(
      <ConfirmModal
        header={t("locations:delete_location")}
        confirmText={t("locations:delete_location")}
        children={
          <>
            <p>{t("locations:delete_confirmation")}</p> <p>{t("locations:delete_connected_gateway_warning")}</p>
          </>
        }
        onConfirm={() => {
          if (locationToDelete == null) return;
          console.log("Deleting location");
          deleteLocation(locationToDelete)
            .then((r) => {
              console.log("Delete result", r);
              showAppModal(null);
              showSnackbar(t("locations:delete_success"), "success");
            })
            .catch((e) => {
              const errorMessage = t("locations:delete_error");
              console.log(errorMessage, e);
              showSnackbar(errorMessage, "error");
              showAppModal(<LocationsModal loc={selectedLocation} />);
            });
        }}
      />,
    );
  };

  const composeValues = (values: ILocationFormProps) => {
    let address = values.street1;

    if (values.street2 && values.street2.length > 0) {
      address += ", " + values.street2;
    }

    return {
      ...selectedLocation,
      ...values,
      address,
      country: values.country.code,
      zip: values.zip,
    } as ILocationDetail;
  };

  const handleAddLocation = () => {
    if (mode !== "createLocation") {
      setMode("createLocation");
    }

    setSelectedLocation(null);
  };

  const createLocation = (values: ILocationFormProps) => {
    setIsSaving(true);

    return addLocation(composeValues(values))
      .then((r) => {
        console.log("Add result", r);
        setSelectedLocation(r);
        showSnackbar(t("locations:add_location_success"), "success");
        return _updateLocationSharedUser(values, r);
      })
      .catch((e) => {
        console.log("Add error", e);
        showSnackbar(t("locations:add_location_error"), "error");
      })
      .finally(() => setIsSaving(false));
  };

  const editLocation = (values: ILocationFormProps) => {
    setIsSaving(true);
    if (mode === "editLocation" && selectedLocation) {
      _updateLocation(composeValues(values), selectedLocation);
    } else if (mode === "createLocation" || mode === "createLocationMobile") {
      createLocation(values);
    }
    showAppModal(null);
  };

  const adjustUsersList = (checked: boolean, user: IUser) => {
    let newAllocatedUsers = [...(allocatedUsers || [])];
    if (checked) {
      newAllocatedUsers.push(user);
    } else {
      newAllocatedUsers = newAllocatedUsers.filter((u) => u._id !== user._id);
    }

    setAllocatedUsers(newAllocatedUsers);
    return newAllocatedUsers;
  };

  const isUserAllocated = (user: IUser) => allocatedUsers.findIndex((u) => u._id === user._id) > -1;

  const _handleSharedUserError = (error: any) => {
    let msg;

    if (error.url && error.url.indexOf("shared_user") > -1) {
      if (typeof error._body === "string") {
        msg = error._body.replace("email address", "shared username");
      }

      if (msg) {
        setIsSaving(false);
        showSnackbar(msg, "error");
      }
    }
  };

  const _updateLocationSharedUser = (values: any, location: ILocation) => {
    if (!isLocalWAM()) {
      return location;
    }

    if (!location.sharedUserId) {
      return createLocationSharedUser(location, {
        identifier: values.sharedUserName,
        password: values.sharedUserPassword,
      } as any);
    } else {
      return updateLocationSharedUser(location, {
        email: values.sharedUserName,
        password: values.sharedUserPassword,
      } as any);
    }
  };

  const _updateLocation = (values: ILocationDetail, location: ILocation) => {
    Promise.all([updateLocation(values), updateLocationUsers(values), _updateLocationSharedUser(values, location)])
      .then((r) => {
        console.log("Update result", r);
        setIsSaving(false);
        showSnackbar(t("locations:update_location_success", { name: location.name }), "success");
      })
      .catch((e) => {
        console.log(e);
        setIsSaving(false);
        showSnackbar(t("locations:update_location_error", { name: location.name }), "error");
        _handleSharedUserError(e);
      });
  };

  const changeAllocatedUsersPagination = (page: number) =>
    // page indexed from 1
    setPaginatedFilteredUsers(
      filteredUsers.slice((page - 1) * numberOfUsersPerPaginatedPage, Math.min(page * numberOfUsersPerPaginatedPage, filteredUsers.length)),
    );

  useEffect(() => {
    setPaginatedFilteredUsers(filteredUsers.slice(0, numberOfUsersPerPaginatedPage) ?? []);
  }, [filteredUsers, searchUserString]);

  const isFirstStepMobile = () => !selectedLocation && mode !== "createLocationMobile";

  // TODO: The old code apparently cared about street-address lines, but didn't store them that way so it had this bit of code to split
  // them out. There are some ways this can break so discuss?
  const address = (selectedLocation?.address || ",").split(",");
  const street1 = address[0];
  const street2 = address[1];

  let sharedUserName = "";
  if (isLocalWAM() && selectedLocation?.sharedUserId) {
    sharedUserName = selectedLocation?.Users?.find((user) => user._id === selectedLocation?.sharedUserId)?.email || "";
  }

  const initialValues = {
    name: selectedLocation?.name || "",
    street1,
    street2,
    city: selectedLocation?.city || "",
    country: country,
    state: selectedLocation?.state || "",
    zip: selectedLocation?.zip?.toString() ?? "",
    phone: selectedLocation?.phone || "",
    timezone:
      selectedLocation?.timezone ??
      Timezone.find((t) => t.utc.includes(Intl.DateTimeFormat().resolvedOptions().timeZone))?.offset.toString() ??
      "",
    // TODO: It looks like in some calls users comes back as an array of strings?
    Users: selectedLocation?.Users || [],
    sharedUserName,
    sharedUserPassword: selectedLocation?.sharedUserPassword || "",
  } as ILocationFormProps;

  return (
    <Formik
      key={countries.length}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={editLocation}
      enableReinitialize={true}>
      {({ isSubmitting, submitForm, setFieldValue }) => {
        return (
          <Modal
            bodyClassName="locations-modal-body"
            title={t("locations:title")}
            titleCloseButton={
              <>
                <div style={{ flex: 1 }} />
                <button
                  onClick={() => showAppModal(null)}
                  className="u-mobile-only"
                  style={{ background: "none", color: "white", border: "none" }}>
                  <XIcon style={{ width: "18px", marginBottom: "-4px" }} />
                </button>
              </>
            }
            buttons={
              <div className="locations-modal-footer">
                {/* Desktop, tablet only */}
                <button className="btn btn-info u-mobile-hide" onClick={() => showAppModal(null)}>
                  {t("common:cancel")}
                </button>
                <button className="btn btn-default u-mobile-hide" onClick={submitForm} disabled={isSubmitting}>
                  {isSubmitting || isSaving ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
                  {mode === "editLocation" ? t("common:save_changes") : t("locations:add_location")}
                </button>
                {/* Mobile small only */}
                <button
                  className={classNames("btn btn-default u-mobile-only", { hidden: !isFirstStepMobile() })}
                  onClick={() => {
                    selectLocation(null);
                    setMode("createLocationMobile");
                  }}>
                  {t("locations:add_location")}
                </button>
                <button
                  className={classNames("btn btn-info u-mobile-only", { hidden: isFirstStepMobile() })}
                  onClick={() => selectLocation(null)}>
                  {t("common:back")}
                </button>
                <button
                  className={classNames("btn btn-default u-mobile-only", { hidden: isFirstStepMobile() })}
                  onClick={submitForm}
                  disabled={isSubmitting}>
                  {isSubmitting || isSaving ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
                  {mode === "editLocation" ? t("common:save_changes") : t("locations:add_location")}
                </button>
                <button
                  className={classNames("btn btn-icon u-text-teal delete-button-mobile u-mobile-only", {
                    hidden: !(!isFirstStepMobile() && mode === "editLocation"),
                  })}
                  onClick={showDeleteConfirm}>
                  <TrashIcon className="u-mobile-only" />
                  <span className="u-text-teal">{t("locations:delete_location")}</span>
                </button>
              </div>
            }>
            <form>
              <div className="left-rail left-panel u-mobile-hide">
                <div className="left-rail-body">
                  <nav className="left-rail-nav">
                    <div className="left-rail-nav-header" style={{ flexDirection: "column" }}>
                      <div className="input-holder u-full-width find-location">
                        <input
                          type="text"
                          className="input input-default"
                          placeholder={t("locations:find_location")}
                          value={searchLocationString}
                          onChange={(e) => setSearchLocationString(e.target.value)}
                        />
                        <i className="fa fa-search input-holder-icon" />
                      </div>

                      <div className="manage-locations-title-section">
                        <div style={{ display: "flex" }}>
                          <StyledTooltip
                            title={"Sort by location name " + (order === "asc" ? "(descending)" : "(ascending)")}
                            enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
                            <button
                              className="btn btn-plain btn-location"
                              onClick={() => setOrder(order === "desc" ? "asc" : "desc")}
                              type="button">
                              {order === "asc" ? <i className="fa fa-sort-amount-desc" /> : <i className="fa fa-sort-amount-asc" />}
                            </button>
                          </StyledTooltip>

                          <h5 className="left-rail-nav-header-title mod-with-btn">{t("locations:location_list")}</h5>
                        </div>

                        <StyledTooltip title={t("locations:add_location")} enterDelay={500}>
                          <div className="btn btn-circle btn-primary add-button" onClick={handleAddLocation}>
                            <i className="fa fa-plus" />
                            <span className="sr-only">{t("locations:add_location")}</span>
                          </div>
                        </StyledTooltip>
                      </div>
                    </div>

                    <ul className="left-rail-nav-group">
                      {sortedLocations?.map((store) => (
                        <li
                          key={store._id}
                          className={classNames("left-rail-nav-item", {
                            active: selectedLocation !== null && selectedLocation?._id === store._id,
                          })}>
                          <div onClick={() => selectLocation(store)} style={{ display: "grid" }}>
                            {store.name || "Location " + store._id}
                            <span className="left-rail-nav-item-sub u-display-block"> {store.address}</span>
                          </div>
                        </li>
                      ))}

                      {mode === "createLocation" ? <li className="left-rail-nav-item active">{t("locations:new_location")}</li> : null}
                    </ul>
                  </nav>
                </div>
              </div>
              {/* Mobile only*/}
              <div className={classNames("u-mobile-only", { hidden: !!selectedLocation || mode === "createLocationMobile" })}>
                <FormFieldSelect
                  options={sortedLocations.map((l) => ({ value: l._id, label: l.name }))}
                  displayError={false}
                  inputClassName="locations-select-mobile"
                  inputPlaceholder="Choose an existing location"
                  onChange={(v) => selectLocation(sortedLocations.find((l) => l._id === v) || null)}
                  name="location"
                />
                <div>
                  <p id="or-text">{t("common:or")}</p>
                </div>
              </div>
              <div
                id="locations-modal-right-panel"
                className={classNames("right-panel main", { mobileHidden: !selectedLocation && mode !== "createLocationMobile" })}>
                {mode === "editLocation" && sortedLocations && sortedLocations.length > 1 ? (
                  <StyledTooltip title={t("locations:delete_location")} enterDelay={500}>
                    <button className="btn btn-icon delete-button" onClick={showDeleteConfirm}>
                      {!isSubmitting ? <i className="fa fa-trash u-mobile-hide" /> : <></>}
                      <span className="sr-only">{t("locations:delete_location")}</span>
                    </button>
                  </StyledTooltip>
                ) : null}

                <div>
                  <FormFieldText name="name" label={t("locations:location_name")} placeholder={t("locations:location_name_placeholder")} />

                  <div className="row">
                    <FormFieldText
                      displayError={true}
                      className="col-sm-6"
                      name="street1"
                      label={t("locations:address_line_1")}
                      placeholder=""
                    />
                    <FormFieldText
                      displayError={true}
                      className="col-sm-6"
                      name="street2"
                      label={t("locations:address_line_2")}
                      placeholder=""
                    />
                  </div>

                  <div id="city-state-zipcode-row" className="row">
                    <FormFieldText className="col-sm-6" name="city" label={t("locations:city")} placeholder="" />
                    <FormFieldText className="col-sm-3" name="state" label={t("locations:state")} placeholder="" />
                    <FormFieldText displayError={true} className="col-sm-3" name="zip" label={t("locations:zip_code")} placeholder="" />
                    <div id="country-select-mobile" className="col-sm-6 u-mobile-only">
                      <label className="input-label u-display-block">{t("locations:country")}</label>
                      <CountrySelect name="country" selectedCountryLabel="name" />
                    </div>
                  </div>

                  <div id="country-phone-row" className="row">
                    <div className="col-sm-6 u-mobile-hide">
                      <label className="input-label u-display-block">{t("locations:country")}</label>
                      <CountrySelect name="country" selectedCountryLabel="name" />
                    </div>
                    <FormFieldText className="col-sm-6" name="phone" label={t("locations:phone")} placeholder="" type="telephone" />
                  </div>

                  <div id="allocated-users-container" className="form-group">
                    <label htmlFor="Users" className="input-label">
                      {t("locations:allocated_users")}
                    </label>
                    <input
                      type="text"
                      className="form-control input-with-icon"
                      placeholder={"\uf002    " + t("locations:search_users")}
                      value={searchUserString}
                      onChange={(e) => setSearchUserString(e.target.value)}
                    />
                    <ul className="select-group fix-height u-mobile-hide">
                      {filteredUsers.map((user) => (
                        <li key={user._id} className="select-group-item">
                          <FormFieldCheckbox
                            onChange={(e) => setFieldValue("Users", adjustUsersList(e.target.checked, user))}
                            id={`user-${user._id}`}
                            className="input-holder"
                            checked={isUserAllocated(user)}
                            name="Users"
                            label={`${user.first_name ?? ""} ${user.last_name ?? ""} ${user.email ?? ""}`}
                          />
                        </li>
                      ))}
                    </ul>
                    <ul className="select-group fix-height u-mobile-only">
                      {paginatedFilteredUsers.map((user) => (
                        <li key={user._id} className="select-group-item">
                          <FormFieldCheckbox
                            onChange={(e) => setFieldValue("Users", adjustUsersList(e.target.checked, user))}
                            id={`user-${user._id}`}
                            className="input-holder"
                            checked={isUserAllocated(user)}
                            name="Users"
                            label={`${user.first_name ?? ""} ${user.last_name ?? ""} ${user.email ?? ""}`}
                          />
                        </li>
                      ))}
                    </ul>
                    <div className="u-mobile-only">
                      <PaginationButtons
                        onPageChange={changeAllocatedUsersPagination}
                        pageCount={Math.ceil(filteredUsers.length / numberOfUsersPerPaginatedPage)}
                        size="small"
                      />
                    </div>
                  </div>

                  {isLocalWAM() ? (
                    <div className="row">
                      {/* Shared user only exist in local wam and also only available to admin user */}
                      <FormFieldText className="col-sm-6" name="sharedUser" label={t("locations:shared_username")} />
                    </div>
                  ) : null}

                  <div className="row">
                    <FormFieldText
                      autoComplete="new-password"
                      type="password"
                      className="col-sm-6"
                      name="sharedPassword"
                      label={t("locations:shared_password")}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
});
