import { ILineChartValue, ISetPoint, RangePicker, SelectInput, TimeSeriesLineChart } from "../../../Components";
import { formatDateCustom, getConverts, getSensorType, prepareChartDataSet } from "../../../Managers";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { IDevice, IExportPreset } from "../../../Managers/Types";
import { IReportSensor } from "../Export";
import { getDeviceSensorData } from "../../../Managers/DeviceService";
import { getUserDateFormat, showAppModal, showSnackbar } from "../../../AppState";
import { ISensorWithExportOptionAndSetPoints } from "./ExportModal";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../../Components/Table";
import { unitsTransform } from "../../../Managers/UnitsService";
import "./PointOfInterestSetup.scss";
import { PointOfInterestTableModal } from "./PointOfInterestTableModal";
import { CircularProgress } from "@mui/material";

export const PointsOfInterestSetup: React.FC<{
  minDate: Date;
  maxDate: Date;
  selectedSensors: ISensorWithExportOptionAndSetPoints[];
  saveSelectedSensors: (sensors: ISensorWithExportOptionAndSetPoints[]) => void;
  savePreset: (preset: IExportPreset) => Promise<void>;
}> = ({ savePreset, minDate, maxDate, selectedSensors, saveSelectedSensors }) => {
  const [selectedDevice, setSelectedDevice] = useState<IDevice>();
  const [selectedSensor, setSelectedSensor] = useState<IReportSensor>();
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(maxDate);
  const [graphLoading, setGraphLoading] = useState(false);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [chartData, setChartData] = useState<ILineChartValue[]>([]);
  const [setPoints, setSetPoints] = useState<ISetPoint[]>([]);
  const [totalElapsedTime, setTotalElapsedTime] = useState("");
  const [setPointsError, setSetPointsError] = useState("");
  const [convertToRh, setConvertToRh] = useState(false);
  const [convertToTemp, setConvertToTemp] = useState(false);

  const { t } = useTranslation(["common", "export"]);

  useEffect(() => {
    if (!selectedSensor) {
      return;
    }

    console.log("Getting sensor data", { startDate, endDate, selectedSensor });

    setGraphLoading(true);
    setSetPointsError("");

    getDeviceSensorData(selectedSensor.sensor, startDate.toISOString(), endDate.toISOString(), true)
      .then((r) => {
        const processed = prepareChartDataSet(r, selectedSensor.sensor, selectedSensor.sensor.is_imperial, false, false);
        setChartData(processed);
        setInitialDataLoaded(true);
      })
      .catch((e) => {
        const errorMessage = t("export:load_error");
        showSnackbar(errorMessage, "error");
        console.log(errorMessage, e);
      })
      .finally(() => setGraphLoading(false));
  }, [endDate, startDate, selectedSensor]);

  useEffect(() => {
    setInitialDataLoaded(false);
    const sensor = selectedSensors.find((s) => s._id === selectedSensor?._id);
    if (sensor) {
      setSetPoints(sensor.setPoints ?? []);
    }
  }, [selectedSensor]);

  // NOTE: This was another block of code I didn't fully understand. I felt it could probably be simplified a lot but was out of time
  // THere was a lot of usage of any-typed values in the old code...
  // find the first interset behinds the target value
  // i.e. in the ascending slope, find the first number that is bigger than the target value,
  // while in the descending slope, find the first number that is smaller than the target value
  const findSetPointForTarget = (target: ISetPoint) => {
    const newSetPoints = setPoints.slice();

    const lastSetPoint = newSetPoints.length ? newSetPoints[newSetPoints.length - 1] : null;
    const firstSetPoint = newSetPoints.length > 0 ? newSetPoints[0] : null;

    if (target._id <= (lastSetPoint?._id ?? 0)) {
      setSetPointsError(t("export:set_point_error"));
      return;
    }

    if (setPoints.length > 0 && target && lastSetPoint && target._id > lastSetPoint._id) {
      target.elapsed = _displayElapseTime(moment(target.x).diff(moment(lastSetPoint.x)));

      if (firstSetPoint) {
        setTotalElapsedTime(_displayElapseTime(moment(target.x).diff(moment(firstSetPoint.x))));
      }
    }

    newSetPoints.push(target);
    setSetPoints(newSetPoints);

    console.log("done finding set point for target");
    console.log(newSetPoints);
  };

  const deviceOptions = useCallback(() => {
    return selectedSensors.reduce((arr, sensor) => {
      if (!arr.some((item) => item._id === sensor.device._id)) {
        arr.push(sensor.device);
      }
      return arr;
    }, [] as IDevice[]);
  }, [selectedSensors]);

  const sensorOptions = useCallback(() => {
    return selectedSensors.reduce((arr, sensor) => {
      if (!arr.some((item) => item._id === sensor._id) && sensor.device._id === selectedDevice?._id) {
        arr.push(sensor);
      }
      return arr;
    }, [] as IReportSensor[]);
  }, [selectedDevice]);

  const _displayElapseTime = (millisecond: number) => {
    if (!millisecond || millisecond < 0) {
      return "00:00";
    }

    let hour, min, sec;
    let totalSecond = Math.round(Math.abs(millisecond) / 1000);

    if (totalSecond >= 3600) {
      hour = Math.floor(totalSecond / 3600);
      totalSecond = totalSecond % 3600;
    }

    min = Math.floor(totalSecond / 60);
    totalSecond = totalSecond % 60;
    sec = totalSecond;

    if (min < 10) {
      min = "0" + min;
    }

    if (sec < 10) {
      sec = "0" + sec;
    }

    return hour ? hour + ":" + min + ":" + sec : min + ":" + sec;
  };

  const clearPoints = () => {
    setSetPoints([]);
    setSetPointsError("");
  };

  useEffect(() => {
    if (selectedSensor?.device) {
      const { convertToRh, convertToTemp } = getConverts(selectedSensor?.device);
      setConvertToRh(convertToRh);
      setConvertToTemp(convertToTemp);
    }
  }, [selectedSensor]);

  const deleteNewPoint = (i: number) => {
    setSetPointsError("");
    const newSetPoints = setPoints.slice();
    newSetPoints.splice(i, 1);
    setSetPoints(newSetPoints);
    _relocateSetPoints(newSetPoints);
  };

  const _relocateSetPoints = (points: ISetPoint[]) => {
    const localSetPoints: ISetPoint[] = [];
    if (points.length) {
      let targets = [...points];

      if (targets.length === 1 && points.length === 1) {
        targets[0].elapsed = "";
        setTotalElapsedTime("");
        setSetPoints(targets);
      }

      targets.forEach(({ _id, unit, ...rest }, i) => {
        // If it cannot find the new setPoint in the updated dataset
        localSetPoints.push({
          _id,
          ...rest,
          elapsed: i > 0 ? _displayElapseTime(moment(targets[targets.length - 1].x).diff(moment(localSetPoints[0].x))) : "",
          unit: unitsTransform(unit, [
            selectedSensor?.sensor?.default_unit,
            selectedSensor?.sensor?.is_imperial,
            selectedSensor?.sensor_type,
            convertToRh,
            convertToTemp,
          ]),
        });
      });

      if (localSetPoints.length) {
        setSetPoints(localSetPoints);
        setTotalElapsedTime(_displayElapseTime(moment(localSetPoints[localSetPoints.length - 1].x).diff(moment(localSetPoints[0].x))));
      } else {
        setTotalElapsedTime("");
      }
    }
  };

  const setFilteredData = (data: ILineChartValue[]) => {
    const sensor = selectedSensors.find((s) => s._id === selectedSensor?._id);
    if (sensor) {
      sensor.data = data;
    }
  };

  const saveSetPoints = () => {
    const modifiedSensors = selectedSensors.slice();
    const sensor = modifiedSensors.find((s) => s._id === selectedSensor?._id);

    if (sensor) {
      sensor.setPoints = setPoints;
      saveSelectedSensors(modifiedSensors);
      showSnackbar(t("export:save_selected_set_points_success"), "success");
    }
  };

  const Range = useCallback(
    () => (
      <RangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        minDate={minDate}
        maxDate={maxDate}
        disabled={!selectedSensor}
        className="vertical"
        hasTimeSelector={true}
      />
    ),
    [minDate, maxDate, selectedSensor, startDate, endDate],
  );

  const handleZoomChange = (domain: string[]) => {
    setStartDate(new Date(domain[0]));
    setEndDate(new Date(domain[1]));
  };

  return (
    <div id="step2">
      <div className="row">
        <div className="col-sm-3">
          <div className="form-group points-range u-mobile-only">
            <Range />
          </div>
          <SelectInput
            style={{ marginTop: 8 }}
            label={t("common:device")}
            menuItemClass="dark"
            inputClassName="dark"
            value={selectedDevice}
            onChange={setSelectedDevice}
            displayEmpty={true}
            options={[
              { value: "", label: t("common:select") },
              ...deviceOptions().map((d) => ({
                value: d,
                label: d.name,
              })),
            ]}
          />
          <SelectInput
            menuItemClass="dark"
            inputClassName="dark"
            value={selectedSensor}
            label={t("common:sensor")}
            onChange={setSelectedSensor}
            displayEmpty={true}
            options={[
              { value: "", label: t("common:select") },
              ...sensorOptions().map((s) => ({
                value: s,
                label: s.sensor_type,
              })),
            ]}
          />
          <div>
            <p className="input-label">{t("export:serial_number")}:</p>
            <p>{selectedDevice?.serial_number ?? "-"}</p>
          </div>
          <div>
            <p className="input-label">{t("export:placement")}:</p>
            <p>{selectedDevice?.location_note ?? "-"}</p>
          </div>
        </div>

        <div className="col-sm-9">
          <div className="form-group points-range u-mobile-hide">
            <Range />
          </div>

          <div className="graph-holder">
            {selectedDevice && selectedSensor && !initialDataLoaded ? (
              <CircularProgress />
            ) : selectedDevice && selectedSensor ? (
              <TimeSeriesLineChart
                data={chartData}
                setPoints={setPoints}
                valueName={getSensorType(selectedDevice, t) || selectedSensor?.sensor_type}
                setFilteredDataHook={setFilteredData}
                onSetPointClick={findSetPointForTarget}
                onZoomDomainChange={handleZoomChange}
                defaultOriginalZoomDomain={[minDate.toISOString(), maxDate.toISOString()]}
                loading={graphLoading}
              />
            ) : null}
          </div>
        </div>
      </div>

      <br />

      {selectedSensor ? (
        <div>
          <div className="u-mobile-hide">
            <span className="sr-only">{t("export:remove_all_targets")}</span>
            <button type="button" className="btn btn-plain" onClick={() => clearPoints()} disabled={!setPoints.length}>
              <i className="fa fa-trash" /> {t("export:clear_all_targets")}
            </button>
          </div>

          <div className="export-modal-table-wrapper">
            <Table className="points-of-interest-table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("export:target_point")}</TableCell>
                  <TableCell>{t("export:reading")}</TableCell>
                  <TableCell>{t("export:date_time")}</TableCell>
                  <TableCell>{t("export:elapsed_time")}</TableCell>
                  <TableCell>{t("export:remove")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {setPoints.map((setPoint, i) => (
                  <TableRow key={setPoint._id}>
                    <TableCell>
                      {setPoint.triggeredTarget}
                      {unitsTransform(selectedSensor.sensor.default_unit, [
                        selectedSensor?.sensor.default_unit,
                        selectedSensor.sensor?.is_imperial,
                        selectedSensor?.sensor?.Sensor_type?.type,
                        convertToRh,
                        convertToTemp,
                      ])}
                    </TableCell>
                    <TableCell>{setPoint.triggeredTarget}</TableCell>
                    <TableCell>{setPoint.x ? formatDateCustom(setPoint.x, getUserDateFormat() + " hh:mm:ss a") : "--"}</TableCell>
                    <TableCell>{setPoint.elapsed || "--:--"}</TableCell>
                    <TableCell>
                      <button type="button" className="btn btn-plain" onClick={() => deleteNewPoint(i)}>
                        <i className="fa fa-times-circle" />
                        <span className="sr-only">{t("export:remove_target")}</span>
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="row u-mobile-only"></div>

          {setPoints.length ? (
            <div className="set-point-action-row">
              <div>
                <span className="u-desktop-hide sr-only">{t("export:remove_all_targets")}</span>
                <button
                  type="button"
                  className="btn btn-plain u-text-teal u-desktop-hide"
                  onClick={() => clearPoints()}
                  disabled={!setPoints.length}>
                  <i className="fa fa-trash u-text-teal" /> {t("export:clear_all_targets")}
                </button>
              </div>
              <div className="pull-right u-text-blue">
                {t("export:total_elapsed_time")}: {totalElapsedTime || "--:--"}
              </div>
            </div>
          ) : null}

          {!setPoints.length ? (
            <>
              <p className="u-text-small u-opacity-fade">{t("export:point_click_info_first_line")}</p>
              <p className="u-text-small u-opacity-fade">{t("export:point_click_info_second_line")}</p>
            </>
          ) : (
            <div className="set-point-action-row set-point-action-buttons">
              <button className="btn btn-primary" onClick={() => saveSetPoints()}>
                {t("export:add_to_exports")}
              </button>
              <button
                onClick={() =>
                  showAppModal(
                    <PointOfInterestTableModal savePreset={savePreset} sensors={selectedSensors} minDate={minDate} maxDate={maxDate} />,
                  )
                }
                className="btn btn-plain u-text-teal">
                <i className="fa fa-eye u-mobile-only" /> {t("export:see_list")}
              </button>
            </div>
          )}

          <p className="u-text-error">{setPointsError}</p>
        </div>
      ) : null}
    </div>
  );
};
